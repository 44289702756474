<template>
  <div class="verifySportDetail">
    <div class="oItem">
      <div class="oItem-head">
        <div class="oItem-t">
          <div class="oItem-tl oItem-time">{{ oItemDetail.insertTime }}</div>
          <div
            :class="['oItem-tr', 'oItem-status', getStatusClass(oItemDetail)]"
          >
            {{ setAuditStatusMap[oItemDetail.auditStatus] }}
          </div>
        </div>
      </div>
      <div class="handle">
        <div class="handle-body">
          <div class="title">详情</div>
          <div class="main">
            <div class="item">
              <div class="item-l">用户名：</div>
              <div class="item-r">{{ oItemDetail.userName }}</div>
            </div>
            <div class="item">
              <div class="item-l">审核备注：</div>
              <div class="item-r">
                <v-input
                  type="textarea"
                  v-model="form.auditRemarks"
                  placeholder="请输入备注"
                ></v-input>
              </div>
            </div>
            <div class="item result">
              <div class="item-l">审核结果：</div>
              <div class="item-r">
                <v-dropDownMenu
                  v-model="form.auditStatus"
                  :option1="setAuditStatusMapOps"
                  @change="change"
                ></v-dropDownMenu>
              </div>
            </div>
          </div>
        </div>

        <div class="item te" v-if="oItemDetail.healthPic">
          <div class="item-l">打卡图片：</div>
          <div class="item-r">
            <div
              class="imgList"
              v-for="(items, indexs) in oItemDetail.healthPic"
              :key="indexs"
            >
              <img :src="items" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sure" v-if="oItemDetail.auditStatus == 0" @click="postHandle">
      确定
    </div>
  </div>
</template>

<script>
import { getSportDetailUrl, verifyUrl } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import {
  setAuditStatusMap,
  auditStatusMap,
  setAuditStatusMapOps,
} from "./map.js";
var moment = require("moment");
export default {
  name: "verifySportDetail",
  data() {
    return {
      setAuditStatusMap,
      oItemDetail: {},
      id: "",
      form: {
        auditStatus: "1",
        auditRemarks: "",
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    setAuditStatusMapOps() {
      let arr = auditStatusMap;
      arr.splice(2, 1);
      return arr;
    },
  },
  mounted() {
    this.getHealthDetailUrl();
  },
  created() {
    this.id = this.$route.query.id;
  },

  methods: {
    change(value) {
      this.form.auditStatus = String(value);
    },
    postHandle() {
      let params = {
        id: this.id,
        adminId: this.userId,
        auditStatus: this.form.auditStatus,
        auditRemarks: this.form.auditRemarks,
        bonusPoints: null,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${verifyUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "审核成功", duration: 2000 });
          this.$router.go(-1);
        } else {
        }
      });
    },
    getStatusClass(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "value").class;
      } else {
        return "";
      }
    },
    getAuditStatus(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "value").text;
      } else {
        return "";
      }
    },
    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },
    getHealthDetailUrl() {
      let params = {
        id: this.id,
      };
      this.$axios.get(`${getSportDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.oItemDetail = res.data;
          if (this.oItemDetail.healthPic) {
            this.oItemDetail.healthPic = this.oItemDetail.healthPic.split(",");
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.verifySportDetail {
  background: #f5f5f5;
  padding: 32px;
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .oItem {
    margin-bottom: 10px;
    .oItem-t {
      display: flex;
      justify-content: space-between;
      font-size: 36px;
      margin-bottom: 32px;
      padding: 28px 32px;
      background: #fff;
      border-radius: 16px;
      .oItem-tl {
        font-size: 28px;
        font-weight: 400;
        color: #646668;
        line-height: 40px;
      }
      .oItem-tr {
        font-size: 32px;
        font-weight: 400;
        color: #fe6f16;
        line-height: 44px;
      }
      .oItem-name {
        margin-left: 15px;
        font-size: 32px !important;
        font-weight: bold;
      }
      .oItem-time {
        font-size: 28px !important;
        font-weight: normal !important;
        color: #999999;
      }

      .out,
      .reback {
        color: #999999;
      }
    }
  }
  .handle {
    margin-top: 20px;
    background: #fff;
    border-radius: 16px;
    padding-bottom: 100px;
    .title {
      padding: 26px 32px;
      border-bottom: 1px solid rgba(245, 245, 245, 0.5);
      font-size: 32px;
      font-weight: 700;
      color: #323334;
      line-height: 44px;
    }
    .item {
      display: flex;
      padding: 26px 32px;
      border-bottom: 1px solid rgba(245, 245, 245, 0.5);
      .item-l {
        width: 160px;
        font-size: 32px;
        font-weight: 400;
        color: #646668;
        line-height: 1;
      }
      .item-r {
        font-size: 30px;
        flex: 1;
      }
      .imgList {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .te {
      display: block;
      .item-r {
        margin-top: 40px;
      }
    }
    .result {
      align-items: center;
    }
  }
  .sure {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: inset 0px 2px 0px 0px rgba(230, 230, 230, 1);
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    line-height: 96px;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: #409eff;
    letter-spacing: 8px;
  }
}
</style>
<style lang="less">
.verifySportDetail {
  .van-dropdown-menu__bar {
    height: 44px;
    padding: 10px;
  }
  .van-dropdown-menu__item {
    justify-content: flex-start;
  }
  .van-dropdown-menu__title::after {
    border-color: transparent transparent #323334 #323334;
  }
  .van-dropdown-menu__title--down::after {
    border-color: transparent transparent #007eff #007eff;
  }
  .van-dropdown-menu__bar {
    box-shadow: none;
  }
  .handle {
    .van-field {
      border: 2px solid #f5f5f5;
    }
  }
}
</style>
